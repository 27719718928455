<template>
  <div class="get-back-password">
    <Card>
      <div slot="title">
        <span>找回密码</span>
      </div>
      <div class="get-backPassword-form">
        <Form :model="getBackPasswordForm" :rules="getBackPasswordRule" ref="getBackPassword">
          <FormItem prop="account">
            <div style="display: flex;align-items: center;position: relative">
              <Input
                type="text"
                class="userPhone"
                v-model="getBackPasswordForm.account"
                placeholder="手机号(仅支持中国大陆地区)或邮箱"
                size="large"
              />
              <div class="showTrueOrFalse">
                <img v-if="phoneJudge" src="../assets/trueOrFalse.png" alt />
              </div>
            </div>
          </FormItem>
          <FormItem prop="code">
            <div class="code" style="display: flex;align-items: center">
              <Input class="codeInput" type="text" v-model="getBackPasswordForm.code" placeholder="验证码" size="large" />
              <el-button class="getCode" :disabled="fetchedCode" style="margin-left: 7px" @click="getCode('getBackPassword')">{{
                !fetchedCode ? '获取验证码' : `${secondsToRefetch}秒后重新获取`
              }}</el-button>
            </div>
          </FormItem>
          <FormItem prop="password">
            <div style="display: flex;align-items: center">
              <Input
                :type="showPassword ? 'text' : 'password'"
                class="inputStyle"
                v-model="getBackPasswordForm.password"
                placeholder="密码"
                size="large"
                autocomplete="new-password"
              />
              <img
                :src="showPassword ? require('../assets/openEye.png') : require('../assets/closeEye.png')"
                class="eye"
                @click="showPassword = !showPassword"
                :style="{ top: showPassword ? '11px' : '17px' }"
              />
            </div>
          </FormItem>
          <FormItem style="margin: 8px 0 11px 0">
            <Button class="submit-newPasswordDisabled" style="width: 100%;" v-if="!newPasswordIsOk" :disabled="disableNewPasswordJudge">确认</Button>
            <Button
              class="submit-newPassword"
              style="width: 100%;background: #5A77D6;color: #FFFFFF"
              @click="submitNewPassword('getBackPassword')"
              v-else
              >确认</Button
            >
          </FormItem>
          <div class="account-use">
            <div class="service">
              <span>已有帐号，<a @click="goLogin" style="color: #5A77D6">马上登录</a></span>
            </div>
            <!--            <div class="title">-->
            <!--              <a to="" class="goBackHome" @click="$router.push( {path: '/home'} )">返回首页</a>-->
            <!--            </div>-->
          </div>
        </Form>
      </div>
    </Card>
  </div>
</template>

<script>
import userApi from '@api/user'
export default {
  name: 'GetBackPassword',
  data() {
    const validatePass = (rule, value, callback) => {
      let pwdRegex = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*\d)(?!.*?[。#?.,!@@$%^&*-])(?!.*?[\u4E00-\u9FA5\uF900-\uFA2D]).{6,12}$/
      if (value === '') {
        callback(new Error('请输入密码'))
      } else if (!pwdRegex.test(value)) {
        callback(new Error('密码必须包含数字和字母'))
      } else {
        callback()
      }
    }
    const validChinesePhone = (rule, value, callback) => {
      var reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/
      let IsEmail = value.indexOf('@')
      if (IsEmail !== -1) {
        // 邮箱
        if (!reg.test(value)) {
          callback(new Error('请输入正确的邮箱'))
        }
      } else {
        if (value === '') {
          callback(new Error('请输入手机号码'))
        } else if (!/^1[3-9]\d{9}$/.test(value)) {
          callback(new Error('请输入正确的手机号'))
        }
      }
      callback()
    }
    return {
      getBackPasswordForm: {
        account: '',
        code: '',
        password: ''
      },
      getBackPasswordRule: {
        account: [
          { required: true, message: '请输入手机号' },
          // { type: 'string', len: 11, message: '手机号长度为11' },
          { validator: validChinesePhone }
        ],
        code: [
          { required: true, message: '请输入验证码' },
          { type: 'string', len: 6, message: '验证码长度为6' },
          {
            type: 'integer',
            message: '手机号为数字',
            transform: value => +value
          }
        ],
        password: [
          { required: true, message: '请输入新密码' },
          { type: 'string', min: 8, message: '密码长度不能小于8' },
          { type: 'string', max: 15, message: '密码长度不能大于15' },
          { validator: validatePass }
        ]
      },
      showPassword: false,
      phoneJudge: false,
      fetchedCode: false,
      secondsToRefetch: 60,
      newPasswordJudge: false,
      codeJudge: false,
      newPasswordIsOk: false,
      disableNewPasswordJudge: false
    }
  },
  watch: {
    'getBackPasswordForm.code'(val) {
      this.codeJudge = false
      if (val.length > 0) {
        this.codeJudge = true
        this.jugeTrueOrFalse()
      } else {
        this.codeJudge = false
        this.jugeTrueOrFalse()
      }
    },
    'getBackPasswordForm.password'(val) {
      this.newPasswordJudge = false
      if (val.length > 0) {
        this.newPasswordJudge = true
        this.jugeTrueOrFalse()
      } else {
        this.newPasswordJudge = false
        this.jugeTrueOrFalse()
      }
    },
    'getBackPasswordForm.account'(val) {
      var reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/
      this.phoneJudge = false
      if (val.length > 0) {
        this.jugeTrueOrFalse()
        if (/^1([3456789])\d{9}$/.test(val) || reg.test(val)) {
          this.phoneJudge = true
        }
      } else {
        this.phoneJudge = false
        this.jugeTrueOrFalse()
      }
    }
  },
  created() {
    this.jugeTrueOrFalse()
  },
  methods: {
    submitNewPassword(formRef) {
      this.$refs[formRef].validate(result => {
        if (result !== false) {
          userApi.submitNewPwd(this.getBackPasswordForm).then(data => {
            if (data.res === true) {
              this.$message.info('密码修改成功')
              this.$emit('toLoginPage')
            }
          })
        } else {
          this.$message.error('请正确输入信息', 1500)
        }
      })
    },
    jugeTrueOrFalse() {
      if (this.codeJudge && this.newPasswordJudge && this.phoneJudge) {
        this.newPasswordIsOk = true
        this.disableNewPasswordJudge = false
      } else {
        this.newPasswordIsOk = false
        this.disableNewPasswordJudge = true
      }
    },
    goLogin() {
      this.$emit('toLoginPage')
    },
    getCode(formRef) {
      this.$refs[formRef].validateField('account', err => {
        if (!err) {
          userApi.getNewPasswordCode(this.getBackPasswordForm.account).then(data => {
            if (data.res === true) {
              this.fetchedCode = true
              this.$message.success('验证码已经发送')
              const interval = setInterval(() => {
                if (this.secondsToRefetch === 0) {
                  this.fetchedCode = false
                  this.secondsToRefetch = 60
                  clearInterval(interval)
                } else {
                  this.secondsToRefetch--
                }
              }, 1000)
            } else {
              this.$message.error('手机号异常，请稍好重试')
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.get-back-password {
  .ivu-card {
    height: 418px;
    width: 900px;
    box-shadow: 2px 3px 10px 0px rgba(175, 179, 181, 0.3);
    &:hover {
      box-shadow: 2px 3px 10px 0px rgba(175, 179, 181, 0.3);
    }
    ::v-deep .ivu-card-head {
      height: 70px;
      padding: 0;
      margin-right: -0.6px;
      background: linear-gradient(90deg, rgba(233, 237, 248, 1), rgba(209, 220, 245, 1));
      div {
        padding-top: 26px;
        span {
          font-size: 18px;
          font-weight: 400;
        }
      }
    }
    ::v-deep .ivu-card-body {
      .get-backPassword-form {
        margin: 0 auto;
        width: 320px;
        .ivu-form {
          margin-top: 41px;
          ::v-deep .ivu-form-item {
            margin-bottom: 25px;
            .ivu-input {
              font-size: 14px;
              &:focus {
                border: 1px solid #9e9d9d;
                box-shadow: none;
              }
              &:hover {
                border-color: #9e9d9d;
              }
            }
            .code {
              display: flex;
              .codeInput {
                flex: 1;
                margin-right: 5px;
              }
              .getCode {
                color: #9e9d9d;
                font-size: 14px;
                height: 40px;
                margin-left: 5px;
                background-color: white;
                border-color: #9e9d9d;
                border-radius: 6px;
                &:hover {
                  color: #888;
                  border-color: #888;
                }
                &.disabled,
                &.disabled:hover {
                  color: #bbbec4;
                  background-color: #f7f7f7;
                  border-color: #dddee1;
                }
              }
            }
            .submit-newPasswordDisabled {
              width: 320px;
              height: 40px;
              color: #9e9d9d;
              background-color: #e1dede;
              border-radius: 6px;
              border: none;
            }
            .submit-newPassword {
              width: 320px;
              height: 40px;
              border-radius: 6px;
              border: none;
              font-size: 16px;
              color: rgba(225, 222, 222, 1);
              &:hover {
                background-color: #506ccb !important;
                color: #ffffff !important;
              }
            }
          }
          .account-use {
            .title {
              float: right;
              font-size: 12px;
              color: #828080;
              ::v-deep a {
                color: #9e9d9d;
              }
            }
            .service {
              float: left;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
::v-deep .ivu-input {
  &:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid #d2d2d2;
  }
  &:hover {
    outline: none;
    box-shadow: none;
    border: 1px solid #d2d2d2;
  }
}
.showTrueOrFalse {
  width: 20px;
  height: 20px;
  right: 11px;
  top: 10px;
  position: absolute;
}
::v-deep .eye {
  position: absolute;
  right: 11px;
  cursor: pointer;
}
</style>
